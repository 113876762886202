import { createModule } from 'redux-modux'

const initialState = {
  products: []
}

// const setPosts = (state, action) => ({
//   ...state,
//   list: action.list
// })

const handlers = {
  // setPosts
}

export default createModule({ moduleName: 'product', initialState, handlers })
