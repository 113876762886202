import { Route, Switch } from 'react-router-dom'
import React, { lazy } from 'react'

const Home = lazy(() => import('./pages/Home'))
const PrivateCommission = lazy(() => import('./pages/PrivateCommission'))
const Enquiries = lazy(() => import('./pages/Enquiries'))
const About = lazy(() => import('./pages/About'))
const Shop = lazy(() => import('./pages/Shop'))
const Payment = lazy(() => import('./pages/Payment'))
const Cart = lazy(() => import('./pages/Cart'))
const OrderReview = lazy(() => import('./pages/OrderReview'))

export default (
  <Switch>
    <Route exact path='/' component={() => <Home />} />
    <Route
      exact
      path='/private-commission'
      component={() => <PrivateCommission />}
    />
    <Route exact path='/enquiries' component={() => <Enquiries />} />
    <Route exact path='/about' component={() => <About />} />
    <Route exact path='/shop/:productID' component={() => <Shop />} />
    <Route exact path='/payment' component={() => <Payment />} />
    <Route exact path='/cart' component={() => <Cart />} />
    <Route exact path='/order-review' component={() => <OrderReview />} />
    <Route component={() => <div>404 Page not found</div>} />
  </Switch>
)
