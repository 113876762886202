import { combineReducers } from 'redux'

import cart from './cart'
import product from './product'
import payment from './payment'

export default combineReducers({
  cart: cart.state,
  product: product.state,
  payment: payment.state
})
