import React, { memo } from 'react'
import styled from '@emotion/styled'

import facebook from '../../images/facebook.svg'
import ig from '../../images/ig.svg'
import whatsapp from '../../images/whatapp.svg'

const fbLink = 'https://www.facebook.com/pamdabureillustrator'
const igLink = 'https://www.instagram.com/pamda_bure/?igshid=1tf3w0ouxjqcj'

export default memo(props => {
  const goto = to => {
    window.location = to
  }

  function tel(number) {
    var aTag = document.createElement('a')
    aTag.href = `tel:${number}`
    aTag.innerHTML = ' '
    aTag.click()
  }

  return (
    <Style>
      <div className='item' onClick={() => goto(fbLink)}>
        <img src={facebook} alt='Facebook' />
      </div>
      <div className='item' onClick={() => goto(igLink)}>
        <img src={ig} alt='Instagram' />
      </div>
      <div className='item' onClick={() => tel('+66815518878')}>
        <img src={whatsapp} alt='Whatsapp' />
      </div>
    </Style>
  )
})

const Style = styled.div`
  label: SocialMedia;
  display: flex;
  justify-content: center;
  margin-top: 74px;

  .item {
    margin: 0 15px;
    cursor: pointer;
  }

  img {
    width: 40px;
    height: 40px;
  }

  h1 {
    font-size: 40px;
  }
`
