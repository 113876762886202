import { Global, css } from '@emotion/core'
import { hot } from 'react-hot-loader'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import React, { Suspense } from 'react'

import 'normalize.css'
import './fonts/stylesheet.css'

import SocialMedia from './components/SocialMedia'
import { history, breakpoint, fluidSizing } from './utils'
import configureStore from './store/configureStore'
import route from './routes'

const store = configureStore(window.__INITIAL_STATE__)

const globalStyles = css`
  html,
  body {
    font-family: 'Brandon Grotesque';
    font-weight: 300;
    background: #000;
    color: #fff;
  }

  .footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    font-size: 14px;
    padding: 0 0 32px;
  }

  h1,
  h2,
  h3,
  h4 {
    font-weight: 500;
  }

  #root {
    position: relative;
    padding: 32px;
    padding-bottom: 65px;
    min-height: 100vh;

    ${breakpoint('SM')} {
      padding: 16px;
      padding-bottom: 80px;
    }
  }

  * {
    box-sizing: border-box;
  }

  p {
    line-height: 1.5;
    margin-top: 5px;

    ${fluidSizing('font-size', {
      0: 16,
      L: 18,
      D: 20
    })}
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    will-change: all;
    transition: all 0.3s ease-in-out;
  }
`

function Root() {
  return (
    <Provider store={store}>
      <Suspense fallback={null}>
        <Global styles={globalStyles} />
        <Router history={history}>{route}</Router>
        <SocialMedia />
        <div className='footer'>All Rights Reserved © PAMDA BURE 2019</div>
      </Suspense>
    </Provider>
  )
}

if (module.hot) {
  module.hot.dispose(() => (window.__INITIAL_STATE__ = store.getState()))
}

export default (process.env.NODE_ENV === 'development'
  ? hot(module)(Root)
  : Root)
