import { createModule } from 'redux-modux'

const initialState = {
  cart: []
}

const addToCart = (state, action) => {
  const isExisting = state.cart.some(p => p.id === action.product.id)

  const increaseQty = () => {
    return state.cart.map(product => {
      if (product.id === action.product.id) {
        return {
          ...product,
          qty: product.qty + 1
        }
      }
      return product
    })
  }


  if (isExisting) {
    return {
      ...state,
      cart: increaseQty()
    }
  }

  return {
    ...state,
    cart: [...state.cart, action.product]
  }
}

const removeFromCart = (state, action) => {
  return {
    ...state,
    cart: state.cart.reduce((result, product) => {
      if (product.id === action.id && product.qty > 0) {
        const item = {
          ...product,
          qty: product.qty - 1
        }

        return [
          ...result,
          item
        ]
      }
      return result
    }, [])
  }
}

const handlers = {
  addToCart,
  removeFromCart
}

export default createModule({ moduleName: 'cart', initialState, handlers })
