import { createModule } from 'redux-modux'

const initialState = {
  fullname: '',
  email: '',
  shippingAddress1: '',
  shippingAddress2: '',
}

// const setPosts = (state, action) => ({
//   ...state,
//   list: action.list
// })

const handlers = {
  // setPosts
}

export default createModule({ moduleName: 'payment', initialState, handlers })
